import {makeAutoObservable} from "mobx";
import BELARUS_320_4_320_4M from "../assets/img/goods/BELARUS-320.4 320.4М.png";
import BELARUS_82_3 from "../assets/img/goods/BELARUS 82.3.png";
import BELARUS_80_1_82_1 from "../assets/img/goods/BELARUS-80.1 82.1.png";
import BELARUS_892_892_2 from "../assets/img/goods/BELARUS-892 892.2.png";
import BELARUS_1025_3 from "../assets/img/goods/BELARUS-1025.3.png";
import BELARUS_1221_2_1221B_2 from "../assets/img/goods/BELARUS-1221.2 1221B.2.png";
import BELARUS_1221_3 from "../assets/img/goods/BELARUS-1221.3.png";
import BELARUS_1523_3_1523T1_3 from "../assets/img/goods/BELARUS-1523.3 1523T1.3.png";
import BELARUS_MPU_320 from "../assets/img/goods/BELARUS MPU-320.png";
import BELARUS_MU_320 from "../assets/img/goods/BELARUS MU-320.png";
import BACKHOE_LOADER_EP_F_1BM from "../assets/img/goods/BACKHOE LOADER EP-F-1BM.png";
import MACHINE_FOR_EXCAVATION_AND_MOVEMENT_OF_SOIL_BL_45_MODIFICATIONS_EP_F_P_01 from "../assets/img/goods/MACHINE FOR EXCAVATION AND MOVEMENT OF SOIL BL-45 MODIFICATIONS EP-F-P-01.png";
import EXCAVATOR_TRENCH_DIGGER_BL_1800_WINTER_SUMMER_WORKING_BODY from "../assets/img/goods/EXCAVATOR - TRENCH DIGGER BL-1800 (WINTER, SUMMER WORKING BODY).png";
import HARVESTING_MACHINE_BL_2500 from "../assets/img/goods/HARVESTING MACHINE BL-2500.png";
import LIFT_BL_09_MOUNTING from "../assets/img/goods/LIFT BL-09 (MOUNTING).png";
import MOUNTED_LIFT_BL_06_ON_AGRICULTURAL_MOUNTING_OF_TRACTOR from "../assets/img/goods/MOUNTED LIFT BL-06 (ON AGRICULTURAL MOUNTING OF TRACTOR).png";
import LIFT_BL_09_01_MANIPULATOR_CRANE from "../assets/img/goods/LIFT BL-09-01 (MANIPULATOR CRANE).png";
import SELF_PROPELLED_HIGH_CLEARANCE_SPRAYER_BL_3000_CLEARANCE_1350MM_1500MM_1780MM from "../assets/img/goods/SELF-PROPELLED HIGH-CLEARANCE SPRAYER BL-3000 (CLEARANCE 1350MM1500MM1780MM).png";
import HIGH_CLEARANCE_TRACTOR_BL_1500 from "../assets/img/goods/HIGH CLEARANCE TRACTOR BL-1500.png";
import BIZON_2PTS_4_5 from "../assets/img/goods/BIZON 2PTS-4.5.png";
import BIZON_2PTS_5 from "../assets/img/goods/BIZON 2PTS-5.png";
import BIZON_2PTS_6_5 from "../assets/img/goods/BIZON 2PTS-6.5.png";

export default class CatalogStore {
    constructor() {
        this._categories = [
            {id: 1, name: 'Тракторы'},
            {id: 2, name: 'Дорожно-строительная техника'},
            {id: 3, name: 'Коммунальная техника'},
            {id: 4, name: 'Навесное оборудование'},
            {id: 5, name: 'Сельскохозяйственная техника'},
            {id: 6, name: 'Прицепы'}
        ]

        this._goods = [
            {
                id: 1,
                categoryId: 1,
                name: 'Трактор BELARUS',
                model: '320.4/320.4М',
                img: BELARUS_320_4_320_4M,
                keywords: 'беларус 320, трактор беларус 320, беларус 320 купить, мтз беларус мтз 320, беларус 320, беларус 320 цена, трактор беларус 320',
                description: 'Трактор предназначен для выполнения различных работ в сельском хозяйстве в агрегате с навесными, ' +
                    'полунавесными и прицепными машинами, в промышленности и на транспорте.' +
                    'Малые габариты, позволяющие применять трактор в теплицах и небольших помещениях.' +
                    'Экологический стандарт Stage IIIa. Передний ведущий мост. Модель «LOMBARDINI»/ММЗ-3LD. Мощность, кВт (л.с.) 26 (36).',
                specifications: [
                    {id: 1, name: 'Тип двигателя', value: 'дизельный'},
                    {id: 2, name: 'Модель двигателя', value: '«LOMBARDINI» LDW1603/В3 (Италия)/ММЗ-3LD (Беларусь)'},
                    {id: 3, name: 'Мощность двигателя, кВт (л.с.)', value: '26 (36)'},
                    {id: 4, name: 'Номинальная частота вращения, об/мин', value: '3000'},
                    {id: 5, name: 'Число цилиндров, шт.', value: '3'},
                    {id: 6, name: 'Рабочий объём, л', value: '1,649/1,600'},
                    {id: 7, name: 'Максимальный крутящий момент, Н•м', value: '92/94'},
                    {id: 8, name: 'Коэффициент запаса крутящего момента, %', value: '12/20'},
                    {id: 9, name: 'Ёмкость топливного бака, л', value: '32'},
                    {id: 10, name: 'Муфта сцепления', value: 'сухая, однодисковая, фрикционная, постоянно замкнутая'},
                    {id: 11, name: 'Коробка передач', value: 'механическая, ступенчатая с шестернями постоянного зацепления, с зубчатыми муфтами лёгкого включения, двухдиапазонная с понижающим редуктором'},
                    {id: 12, name: 'Число передач: вперёд/назад', value: '16/8'},
                    {id: 13, name: 'Скорость движения: вперёд/назад', value: '1,0-25,2/1,8-13,3'},
                    {id: 14, name: 'Задний ВОМ:', value: ''},
                    {id: 15, name: 'независимый I, об./мин.', value: '540'},
                    {id: 16, name: 'независимый II, об./мин.', value: '1000'},
                    {id: 17, name: 'синхронный I, об./м пути', value: '3,4'},
                    {id: 18, name: 'синхронный II, об./м пути', value: '6,3'},
                    {id: 19, name: 'Главная передача', value: 'конические шестерни со спиральными зубьями'},
                    {id: 20, name: 'Дифференциал заднего моста', value: 'шестеренный, конический с 2 сателлитами'},
                    {id: 21, name: 'Конечные передачи', value: 'одноступенчатые редукторы с цилиндрическими прямозубыми шестернями'},
                    {id: 22, name: 'Универсальная, раздельно-агрегатная, присоединение навесных орудий с помощью пальцев к шарнирам продольных (нижних) тяг и центральной тяги', value: ''},
                    {id: 23, name: 'Грузоподъёмность на оси подвеса, кг', value: '1100'},
                    {id: 24, name: 'Максимальное давление, МПа', value: '20'},
                    {id: 25, name: 'Производительность насоса, л/мин.', value: '17'},
                    {id: 26, name: 'Ёмкость гидросистемы, л', value: '11'},
                    {id: 27, name: 'Длина общая (ЗНУ в транспортном положении), мм', value: '3050'},
                    {id: 28, name: 'Ширина (по задним колёсам), мм', value: '1550'},
                    {id: 29, name: 'Высота по кабине, мм', value: '2150'},
                    {id: 30, name: 'База трактора, мм', value: '1660/1690'},
                    {id: 31, name: 'Колея, мм:', value: ''},
                    {id: 32, name: 'по передним колесам', value: '1260; 1410'},
                    {id: 33, name: 'по задним колесам', value: '1250; 1400'},
                    {id: 34, name: 'Агротехнический просвет, мм', value: '435'},
                    {id: 35, name: 'Наименьший радиус поворота, м', value: '3,7'},
                    {id: 36, name: 'Масса эксплуатационная, кг', value: '1680/1770'},
                    {id: 37, name: 'Шины:', value: ''},
                    {id: 38, name: 'передних колёс', value: '7,5L-16'},
                    {id: 39, name: 'задних колёс', value: '12,4L-16'},
                    {id: 40, name: 'Колёсная формула', value: '4х4'}
                ]
            },
            {
                id: 2,
                categoryId: 1,
                name: 'Трактор BELARUS',
                model: '82.3',
                img: BELARUS_82_3,
                keywords: 'трактор BELARUS 82.3, трактор мтз 82.3, купить трактор мтз 82.3, трактор беларус мтз 82.3, новый трактор мтз 82.3, ' +
                    'трактор мтз 82.3 цена, трактор мтз 82.3 новый цена, купить трактор мтз 82.3 новый, трактор мтз 82.3 цена купить',
                description: 'Трактор предназначен для выполнения различных сельскохозяйственных работ с навесными, полунавесными и ' +
                    'прицепными машинами и орудиями, работ на транспорте. Модель двигателя Д-243S2. Мощность двигателя, кВт (л.с.) 60 (81).',
                specifications: [
                    {id: 1, name: 'Тип двигателя', value: 'Дизель с непосредственным впрыском топлива, без турбонаддува'},
                    {id: 2, name: 'Модель двигателя', value: 'Д-243S2 (ММЗ)'},
                    {id: 3, name: 'Мощность двигателя, кВт (л.с.)', value: '60 (81)'},
                    {id: 4, name: 'Номинальная частота вращения, об/мин', value: '2200'},
                    {id: 5, name: 'Число цилиндров, шт.', value: '4'},
                    {id: 6, name: 'Рабочий объём, л', value: '4,75'},
                    {id: 7, name: 'Максимальный крутящий момент, Н•м', value: '298'},
                    {id: 8, name: 'Коэффициент запаса крутящего момента, %', value: '15'},
                    {id: 9, name: 'Ёмкость топливного бака, л', value: '130'},
                    {id: 10, name: 'Муфта сцепления', value: 'сухая, однодисковая диафрагменного типа'},
                    {id: 11, name: 'Коробка передач', value: 'механическая'},
                    {id: 12, name: 'Число передач: вперёд/назад', value: '14/12'},
                    {id: 13, name: 'Скорость движения: вперёд/назад', value: '2,4-35,2 / 2,2-17,6'},
                    {id: 14, name: 'Задний ВОМ:', value: ''},
                    {id: 15, name: 'независимый I, об./мин.', value: '540'},
                    {id: 16, name: 'независимый II, об./мин.', value: '1000'},
                    {id: 17, name: 'синхронный, об./м пути', value: '3,4'},
                    {id: 18, name: 'Универсальная, раздельно-агрегатная с высотным регулированием; по заказу – с силовым и позиционным регулированием глубины обработки почвы.', value: ''},
                    {id: 19, name: 'Грузоподъёмность на оси подвеса, кг', value: '3200-3520'},
                    {id: 20, name: 'Максимальное давление, МПа', value: '20'},
                    {id: 21, name: 'Производительность насоса, л/мин.', value: '45'},
                    {id: 22, name: 'Ёмкость гидросистемы, л', value: '25'},
                    {id: 23, name: 'Длина общая, мм', value: '4120'},
                    {id: 24, name: 'Ширина, мм', value: '1970'},
                    {id: 25, name: 'Высота по кабине, мм', value: '2800'},
                    {id: 26, name: 'База трактора, мм', value: '2450'},
                    {id: 27, name: 'Колея, мм:', value: ''},
                    {id: 28, name: 'по передним колесам', value: '1535-2120'},
                    {id: 29, name: 'по задним колесам', value: '1500-2100'},
                    {id: 30, name: 'Агротехнический просвет, мм', value: '510'},
                    {id: 31, name: 'Наименьший радиус поворота, м', value: '4,5'},
                    {id: 32, name: 'Масса эксплуатационная, кг', value: '4800'},
                    {id: 33, name: 'Шины:', value: ''},
                    {id: 34, name: 'передних колёс', value: '360/70R24'},
                    {id: 35, name: 'задних колёс', value: '18,4R34'},
                    {id: 36, name: 'Колёсная формула', value: '4х4'}
                ]
            },
            {
                id: 3,
                categoryId: 1,
                name: 'Трактор BELARUS',
                model: '80.1/82.1',
                img: BELARUS_80_1_82_1,
                keywords: 'мтз 82.1, мтз 82.1 купить, трактор мтз 82.1, мтз 82.1 цена, мтз 82.1 новый, мтз 82.1 цена новый, купить трактор мтз 82.1, ' +
                    'беларус мтз 82.1, купить мтз 82.1 цены, новый трактор мтз 82.1',
                description: 'Трактор предназначен для выполнения различных сельскохозяйственных работ с навесными, полунавесными и прицепными ' +
                    'машинами и орудиями, транспортных работ. Экологический стандарт Stage II.' +
                    'Трактор BELARUS-80.1 - передняя ось. Трактор BELARUS-82.1 - передний ведущий мост портального типа с коническими редукторами.' +
                    'Двигатель Д-243S2. Мощность, кВт (л. с.) 60 (81). Колёсная формула 4х2/4x4.',
                specifications: [
                    {id: 1, name: 'Тип двигателя', value: 'Дизель с непосредственным впрыском топлива'},
                    {id: 2, name: 'Модель двигателя', value: 'Д-243S2 (ММЗ)'},
                    {id: 3, name: 'Мощность двигателя, кВт (л.с.)', value: '60 (81)'},
                    {id: 4, name: 'Номинальная частота вращения, об/мин', value: '2200'},
                    {id: 5, name: 'Число цилиндров, шт.', value: '4'},
                    {id: 6, name: 'Рабочий объём, л', value: '4,75'},
                    {id: 7, name: 'Максимальный крутящий момент, Н•м', value: '298'},
                    {id: 8, name: 'Удельный расход топлива при номинальной мощности, г/кВт•ч', value: '244'},
                    {id: 9, name: 'Коэффициент запаса крутящего момента, %', value: '15'},
                    {id: 10, name: 'Ёмкость топливного бака, л', value: '130'},
                    {id: 11, name: 'Муфта сцепления', value: 'сухая, однодисковая'},
                    {id: 12, name: 'Коробка передач', value: 'механическая, ступенчатая'},
                    {id: 13, name: 'Число передач: вперёд/назад', value: '18/4'},
                    {id: 14, name: 'Скорость движения: вперёд/назад', value: '1,9-34,3/4,09-9,22'},
                    {id: 15, name: 'Задний ВОМ:', value: ''},
                    {id: 16, name: 'независимый I, об./мин.', value: '540'},
                    {id: 17, name: 'независимый II, об./мин.', value: '1000'},
                    {id: 18, name: 'синхронный, об./м пути', value: '3,4'},
                    {id: 19, name: 'Универсальная, раздельно-агрегатная с высотным регулированием; по заказу – с силовым и позиционным регулированием глубины обработки почвы.', value: ''},
                    {id: 20, name: 'Грузоподъёмность заднего НУ на оси подвеса, кг', value: '3200'},
                    {id: 21, name: 'Максимальное давление, МПа', value: '20'},
                    {id: 22, name: 'Производительность насоса, л/мин.', value: '45'},
                    {id: 23, name: 'Ёмкость гидросистемы, л', value: '25'},
                    {id: 24, name: 'Длина общая, мм', value: '4120'},
                    {id: 25, name: 'Ширина (по концам полуосей задних колёс), мм', value: '1970'},
                    {id: 26, name: 'Высота по кабине, мм', value: '2780/2800'},
                    {id: 27, name: 'База трактора, мм', value: '2370/2450'},
                    {id: 28, name: 'Колея, мм:', value: ''},
                    {id: 29, name: 'по передним колесам', value: '1350-1850/1430-1990'},
                    {id: 30, name: 'по задним колесам', value: '1500-2100'},
                    {id: 31, name: 'Агротехнический просвет, мм', value: '645'},
                    {id: 32, name: 'Наименьший радиус поворота, м', value: '3,8/4,1'},
                    {id: 33, name: 'Глубина преодолеваемого брода, м', value: '0,85'},
                    {id: 34, name: 'Масса эксплуатационная, кг', value: '3770/4000'},
                    {id: 35, name: 'Шины:', value: ''},
                    {id: 36, name: 'передних колёс', value: '9,0-20/11,2-20'},
                    {id: 37, name: 'задних колёс', value: '15,5R38'},
                    {id: 38, name: 'Колёсная формула', value: '4х2/4х4'}
                ]
            },
            {
                id: 4,
                categoryId: 1,
                name: 'Трактор BELARUS',
                model: '892/892.2',
                img: BELARUS_892_892_2,
                keywords: 'трактор 892, трактор мтз 892, трактор беларус 892, куплю трактор 892, купить трактор мтз 892, купить трактор беларус 892, ' +
                    'трактор 892 2, трактор беларусь 892',
                description: 'Трактор предназначен для выполнения различных сельскохозяйственных работ с навесными, полунавесными и прицепными ' +
                    'машинами и орудиями, транспортных работ. Трактор BELARUS-892 - передний ведущий мост с коническими редукторами.' +
                    'Трактор BELARUS-892.2 - передний ведущий мост с планетарно-цилиндрическими редукторами. Экологический стандарт Stage 0.' +
                    'Двигатель Д-245.5. Мощность, кВт (л. с.) 66 (90). Колёсная формула 4х4.',
                specifications: [
                    {id: 1, name: 'Тип двигателя', value: 'Дизель с непосредственным впрыском топлива с турбонаддувом'},
                    {id: 2, name: 'Модель двигателя', value: 'Д-245.5 (ММЗ)'},
                    {id: 3, name: 'Мощность двигателя, кВт (л.с.)', value: '66 (90)'},
                    {id: 4, name: 'Номинальная частота вращения, об/мин', value: '1800'},
                    {id: 5, name: 'Число цилиндров, шт.', value: '4'},
                    {id: 6, name: 'Рабочий объём, л', value: '4,75'},
                    {id: 7, name: 'Максимальный крутящий момент, Н•м', value: '397'},
                    {id: 8, name: 'Удельный расход топлива при номинальной мощности, г/кВт•ч', value: '235'},
                    {id: 9, name: 'Коэффициент запаса крутящего момента, %', value: '15'},
                    {id: 10, name: 'Ёмкость топливного бака, л', value: '130'},
                    {id: 11, name: 'Муфта сцепления', value: 'сухая, однодисковая'},
                    {id: 12, name: 'Коробка передач', value: 'механическая, ступенчатая'},
                    {id: 13, name: 'Число передач: вперёд/назад', value: '18/4'},
                    {id: 14, name: 'Скорость движения: вперёд/назад', value: '2,26-41,1/4,76-11,04'},
                    {id: 15, name: 'Задний ВОМ:', value: ''},
                    {id: 16, name: 'независимый I, об./мин.', value: '540'},
                    {id: 17, name: 'независимый II, об./мин.', value: '1000'},
                    {id: 18, name: 'синхронный, об./м пути', value: '3,36'},
                    {id: 19, name: 'Универсальная, раздельно-агрегатная с высотным регулированием; по заказу – с силовым и позиционным регулированием глубины обработки почвы.', value: ''},
                    {id: 20, name: 'Грузоподъёмность заднего НУ на оси подвеса, кг', value: '3200'},
                    {id: 21, name: 'Максимальное давление, МПа', value: '20'},
                    {id: 22, name: 'Производительность насоса, л/мин.', value: '45'},
                    {id: 23, name: 'Ёмкость гидросистемы, л', value: '25'},
                    {id: 24, name: 'Длина общая, мм', value: '4120/4130'},
                    {id: 25, name: 'Ширина (по концам полуосей задних колёс), мм', value: '1970'},
                    {id: 26, name: 'Высота по кабине, мм', value: '2850'},
                    {id: 27, name: 'База трактора, мм', value: '2450'},
                    {id: 28, name: 'Колея, мм:', value: ''},
                    {id: 29, name: 'по передним колесам', value: '1450-1970/1535-2120'},
                    {id: 30, name: 'по задним колесам', value: '1500-2100'},
                    {id: 31, name: 'Агротехнический просвет, мм', value: '510'},
                    {id: 32, name: 'Наименьший радиус поворота, м', value: '4,1/4,5'},
                    {id: 33, name: 'Глубина преодолеваемого брода, м', value: '0,85'},
                    {id: 34, name: 'Масса эксплуатационная, кг', value: '4150/4250'},
                    {id: 35, name: 'Шины:', value: ''},
                    {id: 36, name: 'передних колёс', value: '13,6-20/360/70R24'},
                    {id: 37, name: 'задних колёс', value: '16,9R38/18,4R34'},
                    {id: 38, name: 'Колёсная формула', value: '4х4'}
                ]
            },
            {
                id: 5,
                categoryId: 1,
                name: 'Трактор BELARUS',
                model: '1025.3',
                img: BELARUS_1025_3,
                keywords: 'беларус 1025, трактор беларус 1025, беларус 1025 характеристики, мтз 1025 беларус, беларус 1025 2, ' +
                    'беларус 1025 технические характеристики, купить беларус 1025, трактор беларус 1025',
                description: 'Трактор предназначен для выполнения различных сельскохозяйственных работ с навесными, полунавесными и прицепными машинами ' +
                    'и орудиями, транспортных работ. Экологический стандарт Stage II. Двигатель Д-245S2. Мощность, кВт (л. с.) 81 (110).',
                specifications: [
                    {id: 1, name: 'Тип двигателя', value: 'Дизель с непосредственным впрыском топлива с турбонаддувом и интеркулером'},
                    {id: 2, name: 'Модель двигателя', value: 'Д-245S2 (ММЗ)'},
                    {id: 3, name: 'Мощность двигателя, кВт (л.с.)', value: '81 (110)'},
                    {id: 4, name: 'Номинальная частота вращения, об/мин', value: '2200'},
                    {id: 5, name: 'Число цилиндров, шт.', value: '4'},
                    {id: 6, name: 'Рабочий объём, л', value: '4,75'},
                    {id: 7, name: 'Максимальный крутящий момент, Н•м', value: '440'},
                    {id: 8, name: 'Удельный расход топлива при номинальной мощности, г/кВт•ч', value: '249'},
                    {id: 9, name: 'Коэффициент запаса крутящего момента, %', value: '25'},
                    {id: 10, name: 'Ёмкость топливного бака, л', value: '135'},
                    {id: 11, name: 'Муфта сцепления', value: 'сухая, двухдисковая'},
                    {id: 12, name: 'Коробка передач', value: 'механическая, синхронизированная'},
                    {id: 13, name: 'Число передач: вперёд/назад', value: '16/8'},
                    {id: 14, name: 'Скорость движения: вперёд/назад', value: '2,3-35,2/4,03-16,5'},
                    {id: 15, name: 'Задний ВОМ:', value: ''},
                    {id: 16, name: 'независимый I, об./мин.', value: '540'},
                    {id: 17, name: 'независимый II, об./мин.', value: '1000'},
                    {id: 18, name: 'синхронный, об./м пути', value: '3,5'},
                    {id: 19, name: 'Управление ВОМ', value: 'гидромеханическое'},
                    {id: 20, name: 'Раздельно-агрегатная, с гидроподъёмником, обеспечивающим силовое, позиционное, смешанное и высотное регулирование положения сельскохозяйственных орудий', value: ''},
                    {id: 21, name: 'Грузоподъёмность заднего НУ на оси подвеса, кг', value: '4200'},
                    {id: 22, name: 'Максимальное давление, МПа', value: '20'},
                    {id: 23, name: 'Производительность насоса, л/мин.', value: '56'},
                    {id: 24, name: 'Ёмкость гидросистемы, л', value: '28'},
                    {id: 25, name: 'Длина общая, мм', value: '4600'},
                    {id: 26, name: 'Ширина (по концам полуосей задних колёс), мм', value: '1970'},
                    {id: 27, name: 'Высота по кабине, мм', value: '2820'},
                    {id: 28, name: 'База трактора, мм', value: '2540'},
                    {id: 29, name: 'Колея, мм:', value: ''},
                    {id: 30, name: 'по передним колесам', value: '1540-2156'},
                    {id: 31, name: 'по задним колесам', value: '1500-2100'},
                    {id: 32, name: 'Агротехнический просвет, мм', value: '645'},
                    {id: 33, name: 'Наименьший радиус поворота, м', value: '5,3'},
                    {id: 34, name: 'Глубина преодолеваемого брода, м', value: '0,85'},
                    {id: 35, name: 'Масса эксплуатационная, кг', value: '5175'},
                    {id: 36, name: 'Шины:', value: ''},
                    {id: 37, name: 'передних колёс', value: '360/70R24'},
                    {id: 38, name: 'задних колёс', value: '18,4R34'},
                    {id: 39, name: 'Колёсная формула', value: '4х4'}
                ]
            },
            {
                id: 6,
                categoryId: 1,
                name: 'Трактор BELARUS',
                model: '1221.2/1221B.2',
                img: BELARUS_1221_2_1221B_2,
                keywords: '1221.2, мтз 1221.2, беларус 1221.2, трактор 1221.2, 1221.2 купить, трактор беларус 1221.2, трактор мтз 1221.2, беларусь 1221.2, цена 1221.2, ' +
                    'куплю мтз 1221.2, купить беларус 1221.2, купить трактор 1221.2',
                description: 'Трактор предназначен для выполнения различных сельскохозяйственных работ с навесными, полунавесными и прицепными машинами и орудиями, транспортных работ.' +
                    'Трактор BELARUS-1221B.2 с реверсивным постом управления. Экологический стандарт Stage I. Двигатель Д-260.2S. Мощность, кВт (л. с.) 96 (130).',
                specifications: [
                    {id: 1, name: 'Тип двигателя', value: 'Дизель с непосредственным впрыском топлива с турбонаддувом'},
                    {id: 2, name: 'Модель двигателя', value: 'Д-260.2S (ММЗ)'},
                    {id: 3, name: 'Мощность двигателя, кВт (л.с.)', value: '96 (130)'},
                    {id: 4, name: 'Номинальная частота вращения, об/мин', value: '2100'},
                    {id: 5, name: 'Число цилиндров, шт.', value: '6'},
                    {id: 6, name: 'Рабочий объём, л', value: '7,12'},
                    {id: 7, name: 'Максимальный крутящий момент, Н•м', value: '500'},
                    {id: 8, name: 'Удельный расход топлива при номинальной мощности, г/кВт•ч', value: '227'},
                    {id: 9, name: 'Коэффициент запаса крутящего момента, %', value: '15'},
                    {id: 10, name: 'Ёмкость топливного бака, л', value: '135'},
                    {id: 11, name: 'Муфта сцепления', value: 'сухая, двухдисковая'},
                    {id: 12, name: 'Коробка передач', value: 'механическая, синхронизированная'},
                    {id: 13, name: 'Число передач: вперёд/назад', value: '16/8'},
                    {id: 14, name: 'Скорость движения: вперёд/назад', value: '1,5-35/2,75-16,4'},
                    {id: 15, name: 'Задний ВОМ:', value: ''},
                    {id: 16, name: 'независимый I, об./мин.', value: '540'},
                    {id: 17, name: 'независимый II, об./мин.', value: '1000'},
                    {id: 18, name: 'синхронный, об./м пути', value: '4,87'},
                    {id: 19, name: 'Управление ВОМ', value: 'электрогидравлическое'},
                    {id: 20, name: 'Универсальная, раздельно-агрегатная с гидроподъёмником, обеспечивающим силовое, позиционное и смешанное регулирование глубины обработки почвы', value: ''},
                    {id: 21, name: 'Грузоподъёмность заднего НУ на оси подвеса, кг', value: '4300'},
                    {id: 22, name: 'Максимальное давление, МПа', value: '20'},
                    {id: 23, name: 'Производительность насоса, л/мин.', value: '51'},
                    {id: 24, name: 'Ёмкость гидросистемы, л', value: '28'},
                    {id: 25, name: 'Длина общая, мм', value: '4500'},
                    {id: 26, name: 'Ширина (по концам полуосей задних колёс), мм', value: '2300'},
                    {id: 27, name: 'Высота по кабине, мм', value: '2850'},
                    {id: 28, name: 'База трактора, мм', value: '2760'},
                    {id: 29, name: 'Колея, мм:', value: ''},
                    {id: 30, name: 'по передним колесам', value: '1540-2090 (ступенчатая)'},
                    {id: 31, name: 'по задним колесам', value: '1530-2150'},
                    {id: 32, name: 'Агротехнический просвет, мм', value: '620'},
                    {id: 33, name: 'Наименьший радиус поворота, м', value: '5,4'},
                    {id: 34, name: 'Глубина преодолеваемого брода, м', value: '0,85'},
                    {id: 35, name: 'Масса эксплуатационная, кг', value: '5730'},
                    {id: 36, name: 'Шины:', value: ''},
                    {id: 37, name: 'передних колёс', value: '420/70R24'},
                    {id: 38, name: 'задних колёс', value: '18,4R38'},
                    {id: 39, name: 'Колёсная формула', value: '4х4'}
                ]
            },
            {
                id: 7,
                categoryId: 1,
                name: 'Трактор BELARUS',
                model: '1221.3',
                img: BELARUS_1221_3,
                keywords: '1221.3, мтз 1221.3, трактор 1221.3, беларус 1221.3, трактор беларус 1221.3, 1221.3 цена, ' +
                    'трактор мтз 1221.3, 1221.3 характеристики, технические характеристики 1221.3, новый 1221.3, мтз 1221.3 цена, ' +
                    'характеристика мтз 1221.3, мтз 1221.3 технические, мтз 1221.3 купить, мтз 1221.3 технические характеристики, 1221.3 тропик',
                description: 'Трактор предназначен для выполнения различных сельскохозяйственных работ с навесными, полунавесными и прицепными машинами и орудиями, транспортных работ.' +
                    'Экологический стандарт Stage II. Двигатель Д-260.2S2. Мощность, кВт (л. с.) 100 (136).',
                specifications: [
                    {id: 1, name: 'Тип двигателя', value: 'Дизель с непосредственным впрыском топлива с турбонаддувом и интеркулером'},
                    {id: 2, name: 'Модель двигателя', value: 'Д-260.2S2 (ММЗ)'},
                    {id: 3, name: 'Мощность двигателя, кВт (л.с.)', value: '100 (136)'},
                    {id: 4, name: 'Номинальная частота вращения, об/мин', value: '2100'},
                    {id: 5, name: 'Число цилиндров, шт.', value: '6'},
                    {id: 6, name: 'Рабочий объём, л', value: '7,12'},
                    {id: 7, name: 'Максимальный крутящий момент, Н•м', value: '568'},
                    {id: 8, name: 'Удельный расход топлива при номинальной мощности, г/кВт•ч', value: '250'},
                    {id: 9, name: 'Коэффициент запаса крутящего момента, %', value: '25'},
                    {id: 10, name: 'Ёмкость топливного бака, л', value: '135'},
                    {id: 11, name: 'Муфта сцепления', value: 'сухая, двухдисковая'},
                    {id: 12, name: 'Коробка передач', value: 'механическая, синхронизированная'},
                    {id: 13, name: 'Число передач: вперёд/назад', value: '16/8'},
                    {id: 14, name: 'Скорость движения: вперёд/назад', value: '1,5-35/2,75-16,4'},
                    {id: 15, name: 'Задний ВОМ:', value: ''},
                    {id: 16, name: 'независимый I, об./мин.', value: '540'},
                    {id: 17, name: 'независимый II, об./мин.', value: '1000'},
                    {id: 18, name: 'синхронный, об./м пути', value: '4,87'},
                    {id: 19, name: 'Управление ВОМ', value: 'электрогидравлическое'},
                    {id: 20, name: 'Универсальная, раздельно-агрегатная с гидроподъёмником, обеспечивающим силовое, позиционное и смешанное регулирование глубины обработки почвы', value: ''},
                    {id: 21, name: 'Грузоподъёмность заднего НУ на оси подвеса, кг', value: '4300'},
                    {id: 22, name: 'Максимальное давление, МПа', value: '20'},
                    {id: 23, name: 'Производительность насоса, л/мин.', value: '51'},
                    {id: 24, name: 'Ёмкость гидросистемы, л', value: '28'},
                    {id: 25, name: 'Длина общая, мм', value: '4500'},
                    {id: 26, name: 'Ширина (по концам полуосей задних колёс), мм', value: '2300'},
                    {id: 27, name: 'Высота по кабине, мм', value: '2850'},
                    {id: 28, name: 'База трактора, мм', value: '2760'},
                    {id: 29, name: 'Колея, мм:', value: ''},
                    {id: 30, name: 'по передним колесам', value: '1540-2090 (ступенчатая)'},
                    {id: 31, name: 'по задним колесам', value: '1530-2150'},
                    {id: 32, name: 'Агротехнический просвет, мм', value: '620'},
                    {id: 33, name: 'Наименьший радиус поворота, м', value: '5,4'},
                    {id: 34, name: 'Глубина преодолеваемого брода, м', value: '0,85'},
                    {id: 35, name: 'Масса эксплуатационная, кг', value: '5730'},
                    {id: 36, name: 'Шины:', value: ''},
                    {id: 37, name: 'передних колёс', value: '420/70R24'},
                    {id: 38, name: 'задних колёс', value: '18,4R38'},
                    {id: 39, name: 'Колёсная формула', value: '4х4'}
                ]
            },
            {
                id: 8,
                categoryId: 1,
                name: 'Трактор BELARUS',
                model: '1523.3/1523T1.3',
                img: BELARUS_1523_3_1523T1_3,
                keywords: 'трактор беларус 1523, 1523, мтз 1523, трактор 1523, купить 1523, беларус 1523, трактор мтз 1523, мтз 1523 купить, 1523 цена, мтз 1221 1523, ' +
                    '1523 3 мтз 1523 цена, 1523 с реверсом',
                description: 'Трактор предназначен для выполнения различных сельскохозяйственных работ общего назначения, основной и предпосевной обработки почвы, посева в составе ' +
                    'широкозахватных и комбинированных агрегатов, уборочных работ в составе высокопроизводительных уборочных комплексов, транспортных работ. Трактор BELARUS-1523Т1.3 ' +
                    'в тропическом исполнении. Экологический стандарт Stage II. Двигатель Д-260.1S2. Мощность, кВт (л. с.) 111 (150).',
                specifications: [
                    {id: 1, name: 'Тип двигателя', value: 'Дизель с непосредственным впрыском топлива с турбонаддувом и интеркулером'},
                    {id: 2, name: 'Модель двигателя', value: 'Д-260.1S2 (ММЗ)'},
                    {id: 3, name: 'Мощность двигателя, кВт (л.с.)', value: '111 (150)'},
                    {id: 4, name: 'Номинальная частота вращения, об/мин', value: '2100'},
                    {id: 5, name: 'Число цилиндров, шт.', value: '6'},
                    {id: 6, name: 'Рабочий объём, л', value: '7,12'},
                    {id: 7, name: 'Максимальный крутящий момент, Н•м', value: '659'},
                    {id: 8, name: 'Удельный расход топлива при номинальной мощности, г/кВт•ч', value: '249'},
                    {id: 9, name: 'Коэффициент запаса крутящего момента, %', value: '25'},
                    {id: 10, name: 'Ёмкость топливного бака, л', value: '250'},
                    {id: 11, name: 'Муфта сцепления', value: 'сухая, двухдисковая'},
                    {id: 12, name: 'Коробка передач', value: 'механическая, синхронизированная'},
                    {id: 13, name: 'Число передач: вперёд/назад', value: '16/8'},
                    {id: 14, name: 'Скорость движения: вперёд/назад', value: '1,73-32,38/2,72-15,52'},
                    {id: 15, name: 'Задний ВОМ:', value: ''},
                    {id: 16, name: 'независимый I, об./мин.', value: '540'},
                    {id: 17, name: 'независимый II, об./мин.', value: '1000'},
                    {id: 18, name: 'синхронный, об./м пути', value: '3,8'},
                    {id: 19, name: 'Электрогидравлическая система автоматического регулирования глубины обработки почвы с силовым, позиционным, смешанным способами регулирования', value: ''},
                    {id: 20, name: 'Грузоподъёмность заднего НУ на оси подвеса, кг', value: '6500'},
                    {id: 21, name: 'Максимальное давление, МПа', value: '20'},
                    {id: 22, name: 'Производительность насоса, л/мин.', value: '55'},
                    {id: 23, name: 'Ёмкость гидросистемы, л', value: '35'},
                    {id: 24, name: 'Длина общая, мм', value: '4710'},
                    {id: 25, name: 'Ширина (по концам полуосей задних колёс), мм', value: '2250'},
                    {id: 26, name: 'Высота по кабине, мм', value: '3000'},
                    {id: 27, name: 'База трактора, мм', value: '2760'},
                    {id: 28, name: 'Колея, мм:', value: ''},
                    {id: 29, name: 'по передним колесам', value: '1540-2115/1800-2120'},
                    {id: 30, name: 'по задним колесам', value: '1520-2435/1800-2435'},
                    {id: 31, name: 'Агротехнический просвет, мм', value: '620'},
                    {id: 32, name: 'Наименьший радиус поворота, м', value: '5,5'},
                    {id: 33, name: 'Глубина преодолеваемого брода, м', value: '0,85'},
                    {id: 34, name: 'Масса эксплуатационная, кг', value: '6250/6330'},
                    {id: 35, name: 'Шины:', value: ''},
                    {id: 36, name: 'передних колёс', value: '420/70R24'},
                    {id: 37, name: 'задних колёс', value: '520/70R38'},
                    {id: 38, name: 'Колёсная формула', value: '4х4'}
                ]
            },
            {
                id: 9,
                categoryId: 2,
                name: 'Экскаватор - погрузчик',
                model: 'ЭП-Ф-1БМ',
                img: BACKHOE_LOADER_EP_F_1BM,
                keywords: 'грейфер, купить грейфер, грейфер двухчелюстной, юмз грейфер, грейферный экскаватор, ' +
                    'экскаватор грейферный купить, экскаватор +с грейферным ковшом, грейферный захват +для экскаватора, ' +
                    'грейферный погрузчик экскаватор, грейферные вилы +на экскаватор, грейферный экскаватор фото, эп ф 1бм',
                description: 'Экскаватор-погрузчик ЭП-Ф-1БМ предназначен для механизации погрузочно-разгрузочных, ' +
                    'землеройных, и строительных работ на агропромышленных и других предприятиях народного хозяйства, ' +
                    'а так же на различных строительных объектах иного назначения',
                specifications : [
                    {id: 1, name: 'Грузоподъемность, т', value: '1.0 ; (0,75)*'},
                    {id: 2, name: 'Высота погрузки, м:', value: ''},
                    {id: 3, name: '- грейфером', value: '3.7; (4,2)*'},
                    {id: 4, name: '- экскаваторной лопатой', value: '2.5; (3.0)*'},
                    {id: 5, name: 'Глубина опускания ниже уровня опорной поверхности, м:', value: ''},
                    {id: 6, name: '- грейфера', value: '2.5; (3,5)*'},
                    {id: 7, name: '- экскаваторной лопаты', value: '2.5; (3,5)*'},
                    {id: 8, name: 'Ширина отвала бульдозера, м', value: '2.0'},
                    {id: 9, name: 'Объем, м3:', value: ''},
                    {id: 10, name: '- грейфера', value: '0.56'},
                    {id: 11, name: '- экскаваторной лопаты', value: '0.27'},
                    {id: 12, name: 'Наибольший вылет стрелы от оси поворота, м', value: '3.9'},
                    {id: 13, name: 'Габаритные размеры с (трактором) в транспортом положении, м', value: ''},
                    {id: 14, name: '- длина', value: '5.5'},
                    {id: 15, name: '- ширина', value: '2.25'},
                    {id: 16, name: '- высота', value: '2.4'},
                    {id: 17, name: 'Общая масса погрузчика без трактора, т', value: '2.4'},
                ]
            },
            {
                id: 10,
                categoryId: 2,
                name: 'Машина для выемки и перемещения грунта',
                model: 'БЛ-45-ЭП-Ф-П-01',
                img: MACHINE_FOR_EXCAVATION_AND_MOVEMENT_OF_SOIL_BL_45_MODIFICATIONS_EP_F_P_01,
                keywords: 'машина +для выемки +и перемещения грунта, экскаватор погрузчик, купить экскаватор погрузчик, ' +
                    'авито экскаватор погрузчик, экскаватор погрузчик цена, экскаватор погрузчик россия, авито купить экскаватор погрузчик, ' +
                    'экскаватор погрузчик отзывы, китайский экскаватор погрузчик, какой экскаватор погрузчик, новый экскаватор погрузчик, ' +
                    'купить экскаватор погрузчик +в россии, экскаватор погрузчик мтз, синий трактор экскаватор, трактор экскаватор погрузчик, ' +
                    'экскаватор погрузчик характеристики, экскаватор погрузчик +в москве, продажа экскаваторов погрузчиков, экскаватор погрузчик купить новый',
                description: 'Все семейство машин БЛ-45 создано на базе промышленного шасси класса 1.4 т.с. ' +
                    '«Беларус» 92П, основными отличиями которого от универсально-пропашного трактора МТЗ-82 ' +
                    'являются наличие мощного переднего ведущего моста балочного типа, реверсивного поста ' +
                    'управления в кабине, усиленной КПП, объемной кабины с развитым остеклением.',
                specifications : [
                    {id: 1, name: 'Тип двигаеля; дизельный, рядный с турбонаддувом', value: ''},
                    {id: 2, name: 'Мощность двигателя  ,кВт(Л.С)', value: '66 (89)'},
                    {id: 3, name: 'Количество цилиндров', value: '4'},
                    {id: 4, name: 'Тип управления рабочими органами', value: 'гидравлический'},
                    {id: 5, name: 'Трансмиссия :механическая ступенчатая', value: ''},
                    {id: 6, name: 'Передний ведущий мост балочного типа с планетарно цилиндрическими колесными редукторами и самоблакирующим ифференциалом', value: ''},
                    {id: 7, name: 'Передние шины', value: '360/70R24'},
                    {id: 8, name: 'Задние шины', value: '18.4К34'},
                    {id: 9, name: 'Число передач-вперед/назад', value: '9/9'},
                    {id: 10, name: 'Дорожный просвет,мм', value: '230'},
                    {id: 11, name: 'Гидрораспределитель секционный, производство Италия', value: '6 или 7 секцинный'},
                    {id: 12, name: 'Емкость гидросистемы,л', value: '180'},
                    {id: 13, name: 'Емкость гидробака,л', value: '130'},
                    {id: 14, name: 'ПОГРУЗОЧНОЕ ОБОРУДОВАНИЕ ФРОНТАЛЬНОЕ', value: ''},
                    {id: 15, name: 'Номинальный объем челюстного ковша', value: '0,65'},
                    {id: 16, name: 'Номинальный объем основного ковша', value: '0,75м3'},
                    {id: 17, name: 'Габаритная рабочая высота с максимально поднятым ковшом', value: '4200 мм.'},
                    {id: 18, name: 'Максимальная высота разгрузки закрытого двух-челюстного ковша', value: '2 700 мм.'},
                    {id: 19, name: 'Максимальная высота разгрузки раскрытого двух-челюстного ковша', value: '3 500мм'},
                    {id: 20, name: 'Толщина срезаемого слоя', value: '170 мм.'},
                    {id: 21, name: 'ЭКСКАВАЦИОННОЕ ОБОРУДОВАНИЕ СО СМЕЩАЕМОЙ ОСЬЮ КОПАНИЯ', value: ''},
                    {id: 22, name: 'Номинальный объем ковша', value: '0,25'},
                    {id: 23, name: 'Исполнение  рукояти;с мех.выдвижением или гидровыдвижением', value: ''},
                    {id: 24, name: 'Номинальная грузоподъемность,тн', value: '0,5'},
                    {id: 25, name: 'Рабочее давление', value: '165 Бар'},
                    {id: 26, name: 'Максимальное усилие резания', value: '35000кН.'},
                    {id: 27, name: 'Максимальная глубина копания', value: '5200 мм.'},
                    {id: 28, name: 'Максимальная высота выгрузки на уровне шарнира', value: '4150 мм.'},
                    {id: 29, name: 'Рабочая высота', value: '5230 мм.'},
                    {id: 30, name: 'Максимальный радиус копания', value: '6100 мм'},
                    {id: 31, name: 'Боковое смещение каретки', value: '+-590мм.+-20'},
                    {id: 32, name: 'Поворот стрелы в плане', value: '180 град.'},
                    {id: 33, name: 'Ширина ковша', value: '630мм'},
                    {id: 34, name: 'Габариты, экскаватора-погрузчика, в мм', value: '7580*2370*3800'},
                    {id: 35, name: 'Масса эксплуатационная, кг', value: '6700 кг.'},
                    {id: 36, name: 'ГИДРОМОЛОТ', value: ''},
                    {id: 37, name: 'Максимальное давление, атм', value: '200'},
                    {id: 38, name: 'Рабочая длина инструмента, мм', value: '400'},
                    {id: 39, name: 'Диаметр рабочего инструмента,мм', value: '68'},
                    {id: 40, name: 'Энекргия удара,Дж', value: '850'},
                    {id: 41, name: 'Частота ударов, уд/мин', value: '450-900'}
                ]
            },
            {
                id: 11,
                categoryId: 2,
                name: 'Экскаватор - траншеекопатель',
                model: 'БЛ-1800',
                img: EXCAVATOR_TRENCH_DIGGER_BL_1800_WINTER_SUMMER_WORKING_BODY,
                keywords: 'баровая установка, траншеекопатель мтз, траншеекопатель бара мтз, купить траншеекопатель мтз, ' +
                    'баровая установка +на мтз, баровая установка +на мтз 82, траншеекопатель бара мтз 82, траншеекопатель +на мтз 82, ' +
                    'траншеекопатель бара мтз купить, траншеекопатель бара мтз 82 купить, баровая установка цена, баровая установка купить, ' +
                    'баровая установка +на трактор, баровая установка +на мтз 82 цена',
                description: 'Предназначен для прокладки газо- и водопроводов, сетей канализации, ' +
                    'связи и электропередачи, используется на разных категориях грунта. Характеристика ' +
                    'категорий грунтов: I – песок, супесок, суглинок легкий; II – суглинок, гравий мелкий ' +
                    'и средний, глина легкая; III – глина средняя или тяжелая, разрыхленная, суглинок плотный; ' +
                    'IV – глина тяжелая со щебнем; V – плотная отвердевшая глина, меловые породы.',
                specifications : [
                    {id: 1, name: 'Базовый трактор', value: 'МТЗ тягового класса 1,4'},
                    {id: 2, name: 'Мощность двигателя, не менее   кВт (л.с.)', value: '57,4 (78 )'},
                    {id: 3, name: 'Максимальная транспортная скорость,  км/ч, не более', value: '18'},
                    {id: 4, name: 'Рабочая скорость при включенном ходоуменьшителе, км/ч', value: '0..5'},
                    {id: 5, name: 'Масса эксплуатационная, кг,   не более', value: '5470'},
                    {id: 6, name: 'Экскаваторное оборудование', value: ''},
                    {id: 7, name: 'Количество передач: прямых', value: '2'},
                    {id: 8, name: 'Количество передач: реверсивных', value: '1'},
                    {id: 9, name: 'Привод рабочего органа', value: 'от ВОМ трактора'},
                    {id: 10, name: 'Цепной скребковый рабочий орган', value: ''},
                    {id: 11, name: 'Ширина копания траншеи,  мм, номин.', value: '210, 270 или 410'},
                    {id: 12, name: 'Глубина копания траншеи, мм', value: '1600-1800'},
                    {id: 13, name: 'Цепной баровый рабочий орган', value: ''},
                    {id: 14, name: 'Ширина копания траншеи, мм, номин.', value: '140'},
                    {id: 15, name: 'Глубина копания траншеи ,мм', value: '1500-1800'},
                    {id: 16, name: 'Универсальный рабочий орган', value: ''},
                    {id: 17, name: 'Тип рабочего органа', value: 'цепной с резцами для дорожных фрез'},
                    {id: 18, name: 'Ширина копания траншеи, мм, номин.', value: '210 или 270'},
                    {id: 19, name: 'Глубина копания траншеи, мм', value: '1500-1800'},
                    {id: 20, name: 'Тип рабочего органа', value: 'цепной скребковый'},
                    {id: 21, name: 'Бульдозерное оборудование', value: ''},
                    {id: 22, name: 'Тип рабочего органа', value: 'легкий навесной с поворот-ным или жестким отвалом'},
                    {id: 23, name: 'Ширина отвала, мм,   не более', value: '2105'},
                    {id: 24, name: 'Высота отвала: без козырька, мм,  не более', value: '660'},
                    {id: 25, name: 'Высота отвала: с козырьком, мм,   не более', value: '825'}
                ]
            },
            {
                id: 12,
                categoryId: 3,
                name: 'Машина уборочная',
                model: 'БЛ-2500',
                img: HARVESTING_MACHINE_BL_2500,
                keywords: 'уборочная машина, подметально уборочная машина, коммунально уборочная машина, дорожно уборочная машина, ' +
                    'подметально уборочная машина купить, уборочная машина +с щетками, комбинированные уборочные машины, ' +
                    'транспортно уборочная машина, уборочная машина цена, уборочная машина +для улицы, уборочные машины +для уборки, ' +
                    'уборочно погрузочная машина, уборочные машины характеристика, машина уборочная беларус, универсальная уборочная машина,  ' +
                    'многофункциональная уборочная машина, трактора уборочные машины, машина уборочная навесная, машина уборочная муп, ' +
                    'уборочные машины +на базе тракторов, машина уборочная на базе мтз',
                description: 'Машина предназначена для механизированной очистки дворов, улиц, площадей и ' +
                    'тротуаров с твердым покрытием от снега, мусора или буксировки легковых автомобилей, ' +
                    'для планировки насыпного грунта, засыпки траншей и ям.',
                specifications : [
                    {id: 1, name: 'Наименование', value: 'Значения'},
                    {id: 2, name: 'Базовый тарктор', value: 'класс 1,4'},
                    {id: 3, name: 'Отвал', value: 'поворотный'},
                    {id: 4, name: 'Управление', value: 'гидравлическое'},
                    {id: 5, name: 'Ширина захвата отвала, мм', value: '2550'},
                    {id: 6, name: 'Угол установки, град.', value: '0-30'},
                    {id: 7, name: 'Щетка', value: 'управление от ВОМа трактора'},
                    {id: 8, name: 'Ширина полосы очистки, мм', value: '1800'},
                    {id: 9, name: 'Угол установки, град.', value: '35'},
                    {id: 10, name: 'Частота вращения, об./мин.', value: '0-160'}
                ]
            },
            {
                id: 13,
                categoryId: 3,
                name: 'Подъемник монтажный',
                model: 'БЛ-09',
                img: LIFT_BL_09_MOUNTING,
                keywords: 'подъемник монтажный, подъемник тракторный, подъемник +для монтажных работ, ' +
                    'строительные +и монтажные подъемники, подъемник телескопический монтажный, ' +
                    'строительные подъемники +и монтажные вышки, монтажный грузовой подъемник, ' +
                    'подъемник вышка +для монтажных работ',
                description: 'Подъёмник монтажный специальный БЛ-09 на базе трактора «Беларус» представляет ' +
                    'собой устройство разнообразного эксплуатационного назначения.',
                specifications : [
                    {id: 1, name: 'Высота обслуживания', value: '11,0 метров'},
                    {id: 2, name: 'наибольший радиус обслуживания', value: '7 метров'},
                    {id: 3, name: 'Фаркоп для транспортировки прицепа  2ПТС-4,5', value: ''},
                    {id: 4, name: 'Грузоподъемность', value: '300 кг'},
                    {id: 5, name: 'Угол поворота стрелы в горизонтальной плоскости', value: '220 градусов'}
                ]
            },
            {
                id: 14,
                categoryId: 3,
                name: 'Подъемник навесной',
                model: 'БЛ-06',
                img: MOUNTED_LIFT_BL_06_ON_AGRICULTURAL_MOUNTING_OF_TRACTOR,
                keywords: 'навесные подъемники, навесной подъемник +на мтз 82, люлька на трактор, подъемник задний навесной',
                description: 'Платформа навесная БЛ-06 представляет собой техническое средство для механизации ' +
                    'широкого круга операций и задач. Платформа является незаменимым помощником в проведении монтажных ' +
                    'и демонтажных работах. Техническое средство может быть использовано для решения повседневных задач ' +
                    'в области сельского хозяйства, для облегчения работы коммунальных, эксплуатационных и других служб с ' +
                    'целью ремонта, технического обслуживания работы промышленных и жилых зданий, конструкций и сооружений.',
                specifications : [
                    {id: 1, name: 'Грузоподъемность, кг (при максим. вылете стрелы с люлькой)', value: '300'},
                    {id: 2, name: 'Высота подъема, и, до:', value: '7'},
                    {id: 3, name: 'При работе с крюком', value: ''},
                    {id: 4, name: 'Размеры рабочей площадки, м', value: '2*1'},
                    {id: 5, name: 'Масса навесного оборудования, кг, не более', value: '650'},
                    {id: 6, name: 'Радиус обслуживания, м, до:', value: '7'},
                    {id: 7, name: 'Угол поворота, градусов', value: '140'}
                ]
            },
            {
                id: 15,
                categoryId: 3,
                name: 'Подъемник (кран-манипулятор)',
                model: 'БЛ-09-01',
                img: LIFT_BL_09_01_MANIPULATOR_CRANE,
                keywords: 'кран манипулятор тракторный, кран манипулятор трактор, кран манипулятор +на мтз, ' +
                    'кран манипулятор +на мтз 82, кран манипулятор, купить кран манипулятор, стреловой кран манипулятор, ' +
                    'авито кран манипулятор, кран манипулятор цена',
                description: 'Подъемник БЛ-09-01 (далее по тексту - подъемник) предназначен для выполнения технологических ' +
                    'операций при строительных и монтажных работах. Он обеспечивает перемещение, погрузку, подъем (спуск) ' +
                    'грузов масса которых не превышает 1000 кг, на высоту до 6м. Работает от ГНС трактора.',
                specifications : [
                    {id: 1, name: 'Базовая машина', value: 'класс 1,4/2'},
                    {id: 2, name: 'Тип привода', value: 'Гидравлический'},
                    {id: 3, name: 'Тип краноманипуляторной установки', value: 'ЛВ-210.00.000-02'},
                    {id: 4, name: 'Грузоподъемность вылете стрелы 1,6м, тн', value: '2,0'},
                    {id: 5, name: 'Грузоподъемность вылете стрелы 2,7м, тн', value: '1,17'},
                    {id: 6, name: 'Грузоподъемность вылете стрелы 3,8м, тн', value: '0,92'},
                    {id: 7, name: 'Грузоподъемность вылете стрелы 5,4м, тн', value: '0,65'},
                    {id: 6, name: 'Максимальный вылет стрелы', value: '5,4 м'},
                    {id: 7, name: 'Угол поворота, град', value: '360'}
                ]
            },
            {
                id: 16,
                categoryId: 3,
                name: 'Трактор BELARUS',
                model: 'МПУ-320',
                img: BELARUS_MPU_320,
                keywords: 'беларус 320, трактор беларус 320, беларус 320 купить, мтз беларус мтз 320, беларус 320 4, ' +
                    'беларус 320 цена, трактор беларус 320 купить, беларус 320 4м, трактор беларус мтз 320, ' +
                    'беларус 320 характеристики, минитрактор беларус 320, трактор беларус 320 4, ' +
                    'трактор беларус 320 4м, беларус 320 м',
                description: 'Машина предназначена для выполнения уборочных и погрузочно-разгрузочных работ, ' +
                    'земляных работ на грунтах I и II категорий, работ по планировке площадок, засыпке ям и траншей.',
                specifications: [
                    {id: 1, name: 'Базовое шасси', value: 'Трактор «БЕЛАРУС-320.3»'},
                    {id: 2, name: 'Масса эксплуатационная, кг', value: '2345'},
                    {id: 3, name: 'Габаритные размеры (длина/ширина/высота), мм', value: '5400/1500/2280'},
                    {id: 4, name: 'Максимальная скорость движения (транспортная/рабочая), км/ч', value: '15/8'},
                    {id: 5, name: 'Двигатель', value: 'Lombardini LDW 1603/B3, 4-х тактный, дизельный, трехцилиндровый, безнаддувный'},
                    {id: 6, name: 'Мощность номинальная двигателя, кВт (л.с.)', value: '26,5 (36)'},
                    {id: 7, name: 'Удельный расход топлива при эксплуатационной мощности, г/кВт·ч', value: '316'},
                    {id: 8, name: 'Муфта сцепления', value: 'cухая, однодисковая'},
                    {id: 9, name: 'Коробка передач', value: 'механическая 4-х ступенчатая, 3-х диапазонная'},
                    {id: 10, name: 'Число передач вперед/назад', value: '16/8'},
                    {id: 11, name: 'Колесная формула', value: '4К4'},
                    {id: 12, name: 'Максимальная высота разгрузки ковша, мм', value: '2000'},
                    {id: 13, name: 'Максимальный угол разгрузки ковша', value: '60°'},
                    {id: 14, name: 'Вместимость ковша, м3', value: '0,25'},
                    {id: 15, name: 'Номинальная грузоподъемность ковша, кг', value: '400'},
                    {id: 16, name: 'Максимальная рабочая глубина копания, мм', value: '100'},
                    {id: 17, name: 'Привод щетки', value: 'от заднего вала отбора мощности (ВОМ) базового шасси'},
                    {id: 18, name: 'Угол установки щетки относительно продольной оси базового шасси', value: '60°'},
                    {id: 19, name: 'Максимальная ширина захвата щетки, мм', value: '1300'},
                    {id: 20, name: 'Производительность', value: ''},
                    {id: 21, name: 'при выполнении погрузочных работ (в зависимости от плотности материала), т/ч', value: '10 – 35'},
                    {id: 22, name: 'при очистке проезжей части от снега, м2/ч', value: '9000'}
                ]
            },
            {
                id: 17,
                categoryId: 3,
                name: 'Трактор BELARUS',
                model: 'МУ-320',
                img: BELARUS_MU_320,
                keywords: 'трактор Беларус МУ-320, беларусь 320 -трактор, мини тракторы, трактор с двигателем Lombardini беларус 320 мк, ' +
                    'беларус 320 мк т характеристики, машина коммунальная беларус 320, машина уборочная МТЗ 320, коммунальная машина мтз, ' +
                    'коммунальная машина +на базе мтз, коммунально уборочная машина мтз',
                description: 'Машина предназначена для очистки улиц, площадей, дорог и тротуаров от мусора и ' +
                    'свежевыпавшего снега, для планировки насыпного грунта и засыпки им траншей и ям.',
                specifications: [
                    {id: 1, name: 'Базовое шасси', value: 'Трактор «БЕЛАРУС-320.3»'},
                    {id: 2, name: 'Габаритные размеры (длина/ширина/высота), мм', value: '4960/1800/2280'},
                    {id: 3, name: 'Максимальная скорость движения (транспортная/рабочая), км/ч', value: '25/10'},
                    {id: 4, name: 'Двигатель', value: 'Lombardini LDW 1603/B3 4-х тактный, дизельный, трехцилиндровый, безнаддувный'},
                    {id: 5, name: 'Мощность номинальная двигателя, кВт (л.с.)', value: '26,5 (36)'},
                    {id: 6, name: 'Удельный расход топлива при эксплуатационной мощности, г/кВт·ч', value: '316'},
                    {id: 7, name: 'Муфта сцепления', value: 'cухая, однодисковая'},
                    {id: 8, name: 'Коробка передач', value: 'механическая 4-х ступенчатая, 3-х диапазонная'},
                    {id: 9, name: 'Число передач вперед/назад', value: '16/8'},
                    {id: 10, name: 'Колесная формула', value: '4К4'},
                    {id: 11, name: 'Максимальная высота убираемого снега за один проход, мм', value: '300'},
                    {id: 12, name: 'Производительность при очистке проезжей части от снега, м2/ч', value: '16100'},
                    {id: 13, name: 'Габаритные размеры бульдозерного оборудования (ширина/высота), мм', value: '1800/600'},
                    {id: 14, name: 'Угол установки отвала относительно продольной оси базового шасси', value: '60° и 90°'},
                    {id: 15, name: 'Максимальная высота подъема отвала, мм', value: '200'},
                    {id: 16, name: 'Максимальная глубина опускания отвала, мм', value: '100'},
                    {id: 17, name: 'Привод щетки', value: 'От заднего вала отбора мощности (ВОМ) базового шасси'},
                    {id: 18, name: 'Угол установки щетки относительно продольной оси базового шасси', value: '60°'},
                    {id: 19, name: 'Максимальная ширина захвата щетки, мм', value: '1300'}
                ]
            },
            {
                id: 18,
                categoryId: 5,
                name: 'Опрыскиватель самоходный высококлиренсный',
                model: 'БЛ-3000',
                img: SELF_PROPELLED_HIGH_CLEARANCE_SPRAYER_BL_3000_CLEARANCE_1350MM_1500MM_1780MM,
                keywords: 'самоходный опрыскиватель, самоходный опрыскиватель туман, купить самоходный опрыскиватель, ' +
                    'самоходный опрыскиватель цена, самоходный опрыскиватель барс, туман 3 опрыскиватель самоходный, ' +
                    'самоходные опрыскиватели джон, самоходный опрыскиватель джон дир, ' +
                    'опрыскиватель разбрасыватель самоходный, самоходные опрыскиватели разбрасыватели туман, ' +
                    'самоходный опрыскиватель рубин, самоходный опрыскиватель характеристика, ' +
                    'самоходные опрыскиватели туман цена, туман 2 опрыскиватель самоходный, ' +
                    'опрыскиватель самоходный туман 2м, самоходный опрыскиватель пантера, ' +
                    'опрыскиватель агрифак самоходный, опрыскиватель разбрасыватель самоходный туман 2м, ' +
                    'опрыскиватель разбрасыватель самоходный туман 3, самоходный опрыскиватель роса, ' +
                    'самоходный опрыскиватель амазон, самоходные опрыскиватели технические характеристики, ' +
                    'авито самоходные опрыскиватели, самоходный опрыскиватель б +у, купить самоходный опрыскиватель туман, ' +
                    'новый самоходный опрыскиватель, производство самоходных опрыскивателей, ' +
                    'самоходный опрыскиватель россия, туман 3 опрыскиватель самоходный цена, ' +
                    'самоходный опрыскиватель john deere, самоходный опрыскиватель амазон пантера, ' +
                    'самоходный опрыскиватель император, самоходный опрыскиватель стара, ' +
                    'опрыскиватель полей самоходный, опрыскиватель самоходный бу, самоходный опрыскиватель фото, ' +
                    'самоходный опрыскиватель хорш, туман опрыскиватель самоходный характеристики, ' +
                    'самоходный опрыскиватель джакто, производители опрыскивателей самоходных, ' +
                    'туман 1 самоходный опрыскиватель, самоходный опрыскиватель император 4000, ' +
                    'самоходный опрыскиватель imperador 4000, опрыскиватель сельскохозяйственный самоходный, ' +
                    'самоходный опрыскиватель запчасти, самоходный опрыскиватель stara, ' +
                    'опрыскиватель самоходный ос 4000м, российские самоходные опрыскиватели, ' +
                    'самоходный опрыскиватель барс 4000м, продажа самоходных опрыскивателей',
                description: 'Опрыскиватель предназначен для химической защиты полевых сельскохозяйственных культур, а также для поверхностного внесения жидких минеральных удобрений.',
                specifications : [
                    {id: 1, name: 'Тип', value: 'Самоходный'},
                    {id: 2, name: 'Колесная формула', value: '4x2 или 4x4'},
                    {id: 3, name: 'Двигатель', value: ''},
                    {id: 4, name: 'Тип', value: 'Рядный дизельный с турбонаддувом, четырёхтактный с непосредственным вспрыском топлива'},
                    {id: 5, name: 'Модель', value: 'Д-245'},
                    {id: 6, name: 'Мощность, (л.с.)', value: '89'},
                    {id: 7, name: 'Номинальная частота вращения коленчатого вала, об./мин.', value: '2200'},
                    {id: 8, name: 'Ёмкость топливных баков, л', value: '130'},
                    {id: 9, name: 'Удельный расход топлива, не более, кг/га', value: '0,7'},
                    {id: 10, name: 'Трансмиссия', value: ''},
                    {id: 11, name: 'Муфта сцепления', value: 'Фрикционная однодисковая, сухая, постоянно замкнутая'},
                    {id: 12, name: 'Коробка передач', value: '9/2 механическая, ступенчатая'},
                    {id: 13, name: 'Скорость движения, км/ч: Вперед/назад', value: '1,9-34,3 / 4,1-9,2'},
                    {id: 14, name: 'Рабочая скорость движения, км/час', value: '4-12'},
                    {id: 15, name: 'Транспортная скорость движения с незаполненными емкостями, км/час, не более', value: '16'},
                    {id: 16, name: 'Система опрыскивания.', value: ''},
                    {id: 17, name: 'Производительность при обработке полевых культур (с нормой вылива рабочей жидкости 200 л/га) за 1 час основного времени', value: '9,6-28,8'},
                    {id: 18, name: 'Рабочая ширина захвата, м', value: '24'},
                    {id: 19, name: 'Вместимость баков', value: ''},
                    {id: 20, name: '- основного, л', value: '2500'},
                    {id: 21, name: '- технологического, л', value: '150'},
                    {id: 22, name: 'Клиренс (агротехнический просвет между балкой опорно-ходовых колес и поверхностью почвы), мм', value: '1350 или 1500'},
                    {id: 23, name: 'Высота установки штанги относительно поверхности почвы (регулируемая), мм', value: '500-2100'},
                    {id: 24, name: 'Расход рабочей жидкости:', value: ''},
                    {id: 25, name: '- при опрыскивании, л/га', value: '100-400'},
                    {id: 26, name: '- при поверхностном внесении жидких минеральных удобрений', value: '100-600'},
                    {id: 27, name: 'Система управления технологическим процессом', value: ''},
                    {id: 28, name: 'Управление внесением химреактивов:', value: ''},
                    {id: 29, name: '- компьютер', value: 'ARAGBravo 180s'},
                    {id: 30, name: '- насос', value: 'мембранно-поршневой'},
                    {id: 31, name: '- гидрораспределитель', value: 'Bondioli & Pavesi'},
                    {id: 32, name: 'Общие параметры', value: ''},
                    {id: 33, name: 'Габаритные размеры в рабочем положении:', value: ''},
                    {id: 34, name: '- длина, мм', value: '9700'},
                    {id: 35, name: '- ширина, мм', value: '24700'},
                    {id: 36, name: '- высота, мм', value: '3650'},
                    {id: 37, name: 'Габаритные размеры в транспортном положении:', value: ''},
                    {id: 38, name: '- длина, мм', value: '9800'},
                    {id: 39, name: '- ширина, мм', value: '2600'},
                    {id: 40, name: '- высота, мм', value: '3650'},
                    {id: 41, name: 'Колея, мм', value: '1800-2300'},
                    {id: 42, name: 'Масса опрыскивателя (без рабочей жидкости и комплекта ЗИП), кг', value: '5500'}
                ]
            },
            {
                id: 19,
                categoryId: 5,
                name: 'Трактор высококлиренсный',
                model: 'БЛ-1500',
                img: HIGH_CLEARANCE_TRACTOR_BL_1500,
                keywords: 'трактор высококлиренсный, вспашка огородов +и садов трактором, садовый трактор, ' +
                    'купить садовый трактор, садовый трактор цена, садовый трактор авито, отзыв трактор садовый, ' +
                    'авито купить садовый трактор, садовые мини тракторы, авито садовый трактор бу, ' +
                    'купить садовый трактор +в москве, садовый самоходный трактор',
                description: 'Трактор высококлиренсный БЛ-1500 ' +
                    '(Шасси самоходное высококлиренсное) с набором сменных модулей',
                specifications : [
                    {id: 1, name: 'Тип', value: 'Колесный'},
                    {id: 2, name: 'Колесная формула', value: '4*2'},
                    {id: 3, name: 'Двигатель, тип', value: 'Дизель'},
                    {id: 4, name: 'Мощность, кВт/л.с', value: '60/80'},
                    {id: 5, name: 'Трансмиссия, тип', value: 'Механическая'},
                    {id: 6, name: 'Тяговый класс, т.с', value: '1.4'},
                    {id: 7, name: 'Клиренс, мм', value: '1500'}
                ]
            },
            {
                id: 20,
                categoryId: 6,
                name: 'Прицеп тракторный БИЗОН',
                model: '2ПТС-4,5',
                img: BIZON_2PTS_4_5,
                keywords: 'прицеп тракторный 2, прицеп тракторный 2 птс, прицеп тракторный бу, тракторный прицеп птс 4, ' +
                    'авито тракторный прицеп, тракторный прицеп 2 птс 4, прицеп тракторный бу купить, ' +
                    'прицеп тракторный самосвальный, купить тракторный прицеп 2, авито купить тракторный прицеп, ' +
                    'тракторный прицеп б +у, купить тракторный прицеп 2 птс, тракторный прицеп 2птс4, ' +
                    'тракторные прицепы б +у купить, тракторные прицепы цены, авито прицеп тракторный бу, ' +
                    'куплю прицеп тракторный птс 4, купить тракторный прицеп 2 птс 4, ' +
                    'купить тракторный прицеп бу +на авито, купить птс +на тракторный прицеп, ' +
                    'прицеп тракторный 4 5, купить прицеп тракторный 2птс4, прицеп тракторный птс 4 5, ' +
                    'прицеп тракторный 2 птс 4 5, прицеп тракторный 2 птс бу',
                description: 'Тракторный самосвальный прицеп БИЗОН 2ПТС-4,5 ' +
                    'предназначен для перевозки сыпучих и штучных грузов, массой ' +
                    'не более 4,5т. Прицеп агрегатируется с тракторами, имеющими ' +
                    'тяговый класс 1.4-3 и оснащенными следующими тягово-сцепными ' +
                    'устройствами: ТСУ-3-В ГОСТ 3481-79, ТСУ ГОСТ 2349-75.',
                specifications : [
                    {id: 1, name: 'Вес, кг', value: '1400'},
                    {id: 2, name: 'Грузоподъёмность, кг', value: '4500'},
                    {id: 3, name: 'Допустимый полный вес, кг', value: '6000'},
                    {id: 4, name: 'Количество осей', value: '2'},
                    {id: 5, name: 'Шины', value: '10/75-15,3; 9-16; 11,5-15,3'},
                    {id: 6, name: 'Длина загрузочного пространства, мм', value: '3790'},
                    {id: 7, name: 'Ширина загрузочного пространства, мм', value: '2270'},
                    {id: 8, name: 'Высота платформы, мм', value: '1270'},
                    {id: 9, name: 'Высота бортов, мм', value: '615'},
                    {id: 10, name: 'Общая высота, мм', value: '1850'},
                    {id: 11, name: 'с надставными цельнометаллическими бортами', value: '2280'},
                    {id: 12, name: 'с надставными сетчатыми бортами', value: '2880'},
                    {id: 13, name: 'Общая длина, мм', value: '5440'},
                    {id: 14, name: 'Общая ширина, мм', value: '2400'},
                    {id: 15, name: 'Объём загрузочного пространства, м3', value: '5,2'},
                    {id: 16, name: 'с надставными бортами', value: '10,4'},
                    {id: 17, name: 'с сетчатыми надставными бортами', value: '13,9'}
                ]
            },
            {
                id: 21,
                categoryId: 6,
                name: 'Прицеп тракторный БИЗОН',
                model: '2ПТС-5',
                img: BIZON_2PTS_5,
                keywords: 'бизон 2 птс 5, 2 птс 5, 2 птс 4 5, прицеп 2 птс 5, прицеп 2 птс 4 5, ' +
                    'прицеп тракторный 2 птс 5, прицеп тракторный 2 птс 4 5, ' +
                    'прицеп тракторный самосвальный 2 птс 5, 2 птс 5 купить, ' +
                    'прицеп самосвальный 2 птс 4 5, 2 птс 4 5 прицеп тракторный самосвальный, ' +
                    'купить прицеп 2 птс 5, купить 2 птс 4 5, 2 птс 5 цена, телега 2 птс 5, ' +
                    'прицеп 2 птс 5 цена, купить прицеп тракторный 2 птс 5, 2 птс 5 характеристики, ' +
                    '2 птс 3 5, прицеп тракторный 2 птс 5 цена, 2 птс 5 технические характеристики, ' +
                    '2 птс 5 мордовагромаш,  прицеп новый 2 птс 5',
                description: 'Тракторный самосвальный прицеп БИЗОН 2ПТС-5 предназначен ' +
                    'для перевозки сыпучих и штучных грузов, массой не более 5т. Прицеп ' +
                    'агрегатируется с тракторами, имеющими тяговый класс 1.4-3 и оснащенными ' +
                    'следующими тягово-сцепными устройствами: ТСУ-3-В ГОСТ 3481-79, ТСУ ГОСТ 2349-75.',
                specifications : [
                    {id: 1, name: 'Вес, кг', value: '1950'},
                    {id: 2, name: 'Грузоподъёмность, кг', value: '5000'},
                    {id: 3, name: 'Допустимый полный вес, кг', value: '6910'},
                    {id: 4, name: 'Количество осей', value: '2'},
                    {id: 5, name: 'Шины', value: '10/75-15,3; 9-16; 11,5-15,3'},
                    {id: 6, name: 'Длина загрузочного пространства, мм', value: '3980'},
                    {id: 7, name: 'Ширина загрузочного пространства, мм', value: '2130'},
                    {id: 8, name: 'Высота платформы, мм', value: '1200'},
                    {id: 9, name: 'Высота бортов, мм', value: '500'},
                    {id: 10, name: 'Общая высота, мм', value: '1700'},
                    {id: 11, name: 'с надставными цельнометаллическими бортами', value: '2200'},
                    {id: 12, name: 'с надставными сетчатыми бортами', value: '2700'},
                    {id: 13, name: 'Общая длина, мм', value: '5750'},
                    {id: 14, name: 'Общая ширина, мм', value: '2340'},
                    {id: 15, name: 'Объём загрузочного пространства, м3', value: '4,2'},
                    {id: 16, name: 'с надставными бортами', value: '8,3'},
                    {id: 17, name: 'с сетчатыми надставными бортами', value: '12,5'}
                ]
            },
            {
                id: 22,
                categoryId: 6,
                name: 'Прицеп тракторный БИЗОН',
                model: '2ПТС-6,5',
                img: BIZON_2PTS_6_5,
                keywords: '2 птс 6, прицеп 2 птс 6, куплю 2 птс 6, 2 птс 6 5, прицеп тракторный 2 птс 6, ' +
                    'купить прицеп 2 птс 6, телега 2 птс 6, прицеп 2 птс 6 5, 2 птс 6 размеры, авито 2 птс 6, ' +
                    'прицеп 2 птс 6 бу, купить прицеп тракторный 2 птс 6, купить прицеп 2 птс 6 бу, ' +
                    '2 птс 6 характеристики, прицепы 2 птс 6 авито, 2 птс 6 купить авито, ' +
                    'прицеп тракторный 2 птс 6 5, 2 птс 6 цена, прицеп самосвальный 2 птс 6, ' +
                    '2 птс 6 прицеп тракторный купить бу, 2 птс 6 б +у, прицеп тракторный самосвальный 2 птс 6, ' +
                    '2 птс 6 технические, прицеп 2 птс 6 характеристики, 2 птс 6 борт, 2 птс 6 технические характеристики',
                description: 'Тракторный самосвальный прицеп БИЗОН 2ПТС-6,5 предназначен для перевозки ' +
                    'сыпучих и штучных грузов, массой не более 6,5 т.Прицеп тракторный агрегатируется с ' +
                    'тракторами, имеющими тяговый класс 1.4-3 и оснащенными следующими тягово-сцепными устройствами:',
                specifications : [
                    {id: 1, name: 'Вес, кг', value: '2230'},
                    {id: 2, name: 'Грузоподъёмность, кг', value: '6500'},
                    {id: 3, name: 'Допустимый полный вес, кг', value: '8730'},
                    {id: 4, name: 'Количество осей', value: '2'},
                    {id: 5, name: 'Шины', value: '11,5-15,3'},
                    {id: 6, name: 'Длина загрузочного пространства, мм', value: '4110'},
                    {id: 7, name: 'Ширина загрузочного пространства, мм', value: '2230'},
                    {id: 8, name: 'Высота платформы, мм', value: '1250'},
                    {id: 9, name: 'Высота бортов, мм', value: '620'},
                    {id: 10, name: 'Общая высота, мм', value: '1870'},
                    {id: 11, name: 'с надставными цельнометаллическими бортами', value: '2370'},
                    {id: 12, name: 'с надставными сетчатыми бортами', value: '2870'},
                    {id: 13, name: 'Общая длина, мм', value: '6150'},
                    {id: 14, name: 'Общая ширина, мм', value: '2390'},
                    {id: 15, name: 'Объём загрузочного пространства, м3', value: '5,7'},
                    {id: 16, name: 'с надставными бортами', value: '10,3'},
                    {id: 17, name: 'с сетчатыми надставными бортами', value: '15,0'}
                ]
            }
        ]

        this._selectedCategory = {}

        makeAutoObservable(this)
    }

    setSelectedCategory(category) {
        this._selectedCategory = category
    }

    get categories() {
        return this._categories
    }

    get goods() {
        return this._goods
    }

    get selectedCategory() {
        return this._selectedCategory
    }
}