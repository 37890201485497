import React from 'react';
import {Container} from "react-bootstrap";
import background from "../assets/img/parallax/Vacancies.jpg";
import ParallaxTitle from "./ParallaxTitle";
import Ancor from "./Ancor";

const Vacancies = () => {
    return (
        <div className="my-5">
            <Ancor id={"vacancies"}/>
            <ParallaxTitle background={background} title={"Вакансии"}/>
            <Container className="app__text">
                <h3><u>Менеджер по продажам</u></h3>
                <div>
                    Обязанности:
                    <ul>
                        <li>Мониторинг предложений о закупках на профильных сайтах.</li>
                        <li>Подготовка документов.</li>
                        <li><b>САМОСТОЯТЕЛЬНАЯ</b> (без привлечения тех.специалистов, специалистов каких-либо других служб) подготовка коммерческого предложения на выставленное ТЗ.</li>
                        <li>Подготовка и утверждение у руководителя расчёта-обоснования участия в закупке (запрос цены у поставщика, расчёт стоимости доставки... и т.д.).</li>
                        <li>Участие в тендерах.</li>
                        <li>Заключение договоров.</li>
                        <li>Оформление заявок на перевозку груза.</li>
                        <li>Отправка и получение грузов в транспортных компаниях.</li>
                        <li>Ведение первичной бухгалтерской документации (выписка счетов, УПД, электронный документооборот).</li>
                        <li>При необходимости - выезд (командировка) к Заказчику для передачи техники и оформления акта приёма-передачи.</li>
                    </ul>
                </div>
                <div>
                    Требования:
                    <ul>
                        <li>Понимание устройства тракторной и автомобильной техники (<b>ОБЯЗАТЕЛЬНО!</b>).</li>
                        <li>Ответственность.</li>
                        <li>Опыт пользования ПК, CRM Bitrix.</li>
                        <li>Наличие уверенного интернет-соединения.</li>
                        <li>Готовность к командировкам по всем регионам РФ (1-3 дня).</li>
                    </ul>
                </div>
                <div>
                    Будет преимуществом:
                    <ul>
                        <li>Опыт работы в компаниях занимающихся поставками спец-, сельхоз-, коммунальной техники.</li>
                        <li>Понимание принципов поставок товаров по гос.закупкам.</li>
                        <li>Опыт участия в гос.закупках (ФЗ-44, ФЗ-233).</li>
                    </ul>
                </div>
                <div>
                    Условия:
                    <ul>
                        <li>Форма оплаты труда: постоянная (оклад) + премиальная часть по результатам работы (квартальная).</li>
                        <li>Оклад по результатам собеседования, от 85 000 ₽ до вычета налогов.</li>
                        <li>Официальное трудоустройство.</li>
                        <li>Удаленная работа, <b>НО</b> (!) полная занятость, полный день.</li>
                        <li>Гибкий график работы.</li>
                    </ul>
                </div>
                <div>
                    Ключевые навыки:
                    <ul>
                        <li>Подготовка коммерческих предложений</li>
                        <li>Тендеры</li>
                        <li>Знание устройства автомобиля</li>
                        <li>Знание устройства трактора</li>
                    </ul>
                </div>
                <p>
                    Возможно временное оформление: договор услуг, подряда, ГПХ, самозанятые, ИП.
                </p>
                <p>
                    Своё резюме присылайте на e-mail: <a href="mailto:gd@tc-prioritet.ru">gd@tc-prioritet.ru</a>.
                </p>
            </Container>
        </div>
    );
};

export default Vacancies;