import React, {useState} from 'react';
import PartnersList from "./PartnersList";
import {Button, Container} from "react-bootstrap";
import '../styles/Leasing.scss'
import CreateRequest from "./modals/CreateRequest";
import background from "../assets/img/parallax/Leasing.jpg";
import ParallaxTitle from "./ParallaxTitle";
import Ancor from "./Ancor";

const Leasing = () => {
    const [createRequestVisible, setCreateRequestVisible] = useState(false)

    return (
        <div className="leasing my-5">
            <Ancor id={"leasing"}/>
            <ParallaxTitle background={background} title={"Лизинг"}/>
            <Container className="app__text">
                <h3 className="mt-5"><u>Что такое лизинг</u></h3>
                <p>
                    Лизинг – это финансовый инструмент, позволяющий предприятию получить в
                    пользование автотранспорт, технику, оборудование, недвижимость и другие,
                    не потребляемые предметы, не отвлекая серьезных денежных средств из
                    оборота компании.
                </p>
                <h3 className="mt-5"><u>Преимущества лизинга</u></h3>
                <p>
                    Основное преимущество лизинга – возможность списания лизинговых платежей
                    на затраты предприятия. Таким образом, база для расчета налога на прибыль
                    ниже по сравнению с кредитом, где на затраты можно относить только проценты,
                    а не весь платеж по кредиту. Лизинг является разновидностью арендных отношений
                    и относится к операциям по оказанию услуг, поэтому лизинговые платежи
                    облагаются НДС. Соответственно можно получить налоговый вычет по НДС в том же
                    квартале, когда были проведены платежи, на основании счетов-фактур.
                    Среди других преимуществ использования лизинга – высокая скорость прохождения
                    сделки и доступность получения финансирования. Дело в том, что при покупке авто
                    в лизинг транспортное средство остается в собственности лизинговой компании до
                    окончания срока действия договора. Оно и является основным обеспечением сделки.
                    Поэтому, при обращении в лизинговую компанию к заемщику предъявляются более гибкие
                    и лояльные требования. В сравнении с покупкой авто за собственные средства, лизинг
                    не требует крупных единовременных финансовых вложений. Размер авансового платежа по
                    договору лизинга может варьироваться в диапазоне от 0% до 49% от стоимости
                    приобретаемого имущества.
                </p>
                <h3 className="mt-5"><u>Берите технику сейчас, а платите потом! Что может быть заманчивей?</u></h3>
                <p>
                    Лизинг – удобный инструмент для постоянного расширения и обновления парка техники
                    Вашей компании. Лизинг – это долгосрочная аренда имущества, по окончании которой
                    имущество передается в собственность лизингополучателю.
                </p>
                <h3 className="mt-5"><u>Условия, которые предлагают наши партнеры:</u></h3>
                <div>
                    <ol>
                        <li>Срок лизинга: от 1 до 7 лет;</li>
                        <li>Авансовый платеж: от 10% до 49% стоимости техники;</li>
                        <li>Срок деятельности предприятия: от 6 месяцев;</li>
                        <li>Графики платежей: аннуитетный, регрессный, сезонный.</li>
                    </ol>
                </div>
                <h3 className="mt-5"><u>Специальное предложение</u></h3>
                <p>
                    У нас вы можете приобрести <b>БЕЛОРУССКУЮ</b> технику в
                    лизинг на выгодных условиях по программе субсидирования!
                </p>
                <div>
                    Условия программы:
                    <ol>
                        <li>Выгода: 2/3 ключевой ставки ЦБ РФ;</li>
                        <li>От Аванс – от 10%.</li>
                    </ol>
                </div>
                <div className="text-center my-5">
                    <Button
                        className="flare-button"
                        onClick={() => setCreateRequestVisible(true)}
                    >
                        <h2 className="m-0 py-3">Оставить заявку</h2>
                    </Button>
                </div>
                <h3 className="mt-5"><u>Наши потенциальные финансовые партнеры</u></h3>
                <div>
                    <PartnersList/>
                </div>
            </Container>
            <CreateRequest
                show={createRequestVisible}
                onHide={() => setCreateRequestVisible(false)}
                good={false}
                model={false}
                leasing={true}
                tenders={false}
            />
        </div>
    );
};

export default Leasing;