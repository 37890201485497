import React from 'react';
import Catalog from "../components/Catalog";
import {Helmet} from "react-helmet";
import NavBar from "../components/NavBar";
import AboutUs from "../components/AboutUs";
import Leasing from "../components/Leasing";
import Tenders from "../components/Tenders";
import Clients from "../components/Clients";
import Vacancies from "../components/Vacancies";
import Contacts from "../components/Contacts";
import PhoneButton from "../components/PhoneButton";
import Header from "../components/Header";

const Main = () => {
    return (
        <div>
            <Helmet>
                <meta name="keywords" content="ооо техцентр приоритет, техцентр приоритет, тракторы, дорожно-строительная техника,
                коммунальная техника, навесное оборудование, сельскохозяйственная техника, прицепы, мтз, купить трактор, купить
                трактор т, купить трактор мтз, купить трактор область, авито купить трактор мтз, купить трактор цена, купить
                трактор мтз 80, белагро, купить мини трактор, купить новый трактор, купить трактор мтз +в области, купить
                трактор недорого, где купить трактор, лбр, строительная техника, дорожно строительная, каталог дорожно строительной
                техники, строительно дорожные машины строительная техника, продажа дорожно строительной техники, дорожно строительная
                техника россия, производство дорожно строительной техники, какую дорожно строительную технику, трактор дорожно
                строительная техника, дорожно строительная техника купить, беловеж, компании дорожно строительной техники,
                оборудование дорожно строительную технику, фронтальный погрузчик, купить фронтальный погрузчик, авито фронтальный
                погрузчик, китайский фронтальный погрузчик, фронтальный погрузчик цена, фронтальный погрузчик амкодор, амкодор,
                фронтальный погрузчик бу купить, фронтальный погрузчик sdlg, фронтальный погрузчик характеристики, какой фронтальный
                погрузчик, доминант, спецтехника, купить спецтехнику, продажа спецтехники, авито спецтехника, спецтехника область,
                лизинг спецтехники, спецтехника россии, край спецтехника, технодвор центр, спецтехника москва, сайт спецтехники,
                синий трактор экскаватор, прицеп тракторный, купить прицеп тракторный, тракторные прицепы птс, прицеп тракторный
                самосвальный, прицеп тракторный самосвальный 2 птс, продажа тракторных прицепов, характеристика тракторного прицепа,
                сельхозтехника, авито сельхозтехника, купить сельхозтехнику, сельхозтехника авито область, продажа сельхозтехники,
                авито сельхозтехника бу, сельхозтехника трактора, купить сельхозтехнику бу, край сельхозтехника, авито купить
                сельхозтехнику, авито сельхозтехника б +у, купить сельхозтехника +в области, продажа сельхозтехники бу, магазин
                сельхозтехники, продажа сельхозтехники +в области, авито трактора +и сельхозтехника, сельхозтехника трактора б,
                продажа сельхозтехники +на авито, сельхозтехника россия, авито сельхозтехника трактора б, район сельхозтехника"/>
                <meta name="description" content="ООО Техцентр Приоритет. Профиль деятельности компании - поставки тракторной,
                дорожно-строительной и коммунальной техники по государственным закупкам и тендерным торгам крупных компаний."/>
                <title>Техцентр Приоритет</title>
            </Helmet>
            <PhoneButton/>
            <Header/>
            <NavBar/>
            <AboutUs/>
            <Catalog/>
            <Leasing/>
            <Tenders/>
            <Clients/>
            <Vacancies/>
            <Contacts/>
        </div>
    );
};

export default Main;