import React from 'react';
import {Container} from "react-bootstrap";
import background from "../assets/img/parallax/AboutUs.jpg";
import ParallaxTitle from "./ParallaxTitle";
import Ancor from "./Ancor";

const AboutUs = () => {
    return (
        <div className="mb-5">
            <Ancor id={"about"}/>
            <link
                rel="preload"
                fetchpriority="high"
                as="image"
                href={background}
                type="image/webp"
            />
            <ParallaxTitle background={background} title={"О нас"}/>
            <Container className="app__text">
                <p>
                    ООО «Техцентр Приоритет» занимается поставками качественной коммунальной, дорожно-строительной и
                    сельскохозяйственной техники от ведущих отечественных производителей. Мы предлагаем нашим клиентам
                    широкий ассортимент продукции: коммунальные, снегоуборочные машины, экскаваторы-погрузчики,
                    тракторы, прицепы, навесное оборудование и многие другие виды продукции.
                </p>
                <p>
                    Наша компания специализируется на поставке <b>только проверенных и надежных моделей техники</b>, которые
                    соответствуют всем современным требованиям и стандартам качества. Мы работаем только с теми производителями,
                    которые имеют безупречную репутацию на мировом рынке и предлагают продукцию с высокой степенью надежности
                    и производительности.
                </p>
                <p>
                    ООО «Техцентр Приоритет» состоит из высококвалифицированных специалистов, преданных своему делу и
                    стремящихся постоянно совершенствовать свою работу. Мы гордимся тем, что наши сотрудники всегда готовы
                    прийти на помощь нашим клиентам и партнерам, обеспечивая им профессиональную поддержку и
                    индивидуальный подход.
                </p>
                <p>
                    Мы верим, что наше сотрудничество с клиентами и партнерами строится на взаимном доверии и уважении.
                    ООО «Техцентр Приоритет» стремится быть надежным партнером для своих клиентов, гарантируя качество
                    и своевременность поставок продукции и оказания услуг.
                </p>
                <div>
                    Сотрудничество с ООО «Техцентр Приоритет» имеет ряд преимуществ:
                    <ul>
                        <li>Мы предлагаем большой выбор техники различных производителей, что позволяет подобрать
                            оптимальный вариант для каждого клиента.</li>
                        <li>Выгодные условия поставки. Мы предлагаем гибкие условия оплаты и поставки.</li>
                        <li>Наши специалисты всегда готовы оказать помощь в выборе техники.</li>
                        <li>Индивидуальный подход к каждому клиенту!</li>
                    </ul>
                </div>
                <p>
                    В будущем мы планируем продолжать расширять наш ассортимент продукции и географию присутствия,
                    чтобы способствовать развитию наших клиентов и укреплению позиций на рынке.
                </p>
            </Container>
        </div>
    );
};

export default AboutUs;